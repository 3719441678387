// Main Pallette
$dark_info: #006bb4;
$dark_primary: #0077cc;
$dark_primary_light: #36a2ef;
$dark_primary_box_behind_text: #79aad9;
$dark_accent: #f04e98;
$dark_accent_light: #f68fbe;
$dark_success: #00bfb3;
$dark_success_light: #7dded8;
$dark_warning: #fec514;
$dark_warning_light: #f3d371;
$dark_danger: #bd271e;
$dark_danger_light: #f86b63;
$dark_white: #ffffff;
$dark_white_one: #f5f7fa;
$dark_white_two: #d3dae6;
$dark_white_three: #cdd4df;
$dark_grey_one: #333333;
$dark_grey_two: #4f4f4f;
$dark_grey_three: #98a2b3;
$dark_grey_four: #69707d;
$dark_grey_five: #2a2b31;
$dark_grey_six: #141518;
$dark_grey_seven: #828282;
$dark_grey_eight: #1d1e24;
$dark_black_darkest: #343741;
$dark_grey_nine: #525761;
$dark_grey_nine_text: #fafbfd;
$dark_black: #000000;

// Security Banner
$exercise_banner: #f0ad4e;
$exercise_text: #ff0000;
$unclassified_banner: #61a36a;
$unclassified_text: #ffffff;
$confidential_banner: #33ffff;
$confidential_text: #000000;
$secret_banner: #ff0000;
$secret_text: #ffffff;
$secret_sci_banner: #ff0000;
$secret_sci_text: #000000;
$top_secret_banner: #ff9900;
$top_secret_text: #ffffff;
$top_secret_sci_banner: #ffff00;
$top_secret_sci_text: #000000;

//Data Source
$data_source_card_switch_button: #27ae60;
$data_source_card_border: #bdbdbd;

//Tooltips
$help_tooltip_color: #000000;
$help_tooltip_text_color: #ffffff;

//Exercise Banner defaults (db config overwrites this)
$exercise_banner_default_background: #adeeac;
$exercise_banner_default_text_color: #000000;
