@import "./colors.scss";

.euiFormLabel.euiFormLabel-isFocused {
  color: $dark_white !important;
}

.euiFieldNumber,
.euiSuperSelectControl {
  &:focus {
    background-image: linear-gradient(
      to top,
      $dark_warning,
      $dark_warning 2px,
      transparent 2px,
      transparent 100%
    );
  }
}

.euiSuperSelectControl.euiSuperSelect--isOpen__button,
.euiSuperSelectControl--compressed:focus {
  background-image: linear-gradient(
    to top,
    $dark_warning,
    $dark_warning 2px,
    transparent 2px,
    transparent 100%
  ) !important;
}

// Tries to make sure the dropdown is always on top
.euiPanel.euiPanel--plain.euiPopover__panel {
  z-index: 200001;
}
