@keyframes animatedBackground {
  from {
    background-position: -400px 0;
  }
  to {
    background-position: 400px 0;
  }
}

.loading-container {
  width: 100%;
  height: 200px;
  margin: 4px 0;

  .card-icons-container {
    height: 30px;
    width: 60%;
  }

  .card-title-container {
    height: 24px;
    width: 100%;
    margin: 16px 0;
    padding-left: 10px;
  }

  .skeleton {
    background: rgba(217, 217, 217, 0.3);
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(8%, rgba(217, 217, 217, 0.3)),
      color-stop(18%, rgba(217, 217, 217, 0.4)),
      color-stop(33%, rgba(217, 217, 217, 0.3))
    );
    background: linear-gradient(
      to right,
      rgba(217, 217, 217, 0.3) 8%,
      rgba(217, 217, 217, 0.4) 18%,
      rgba(217, 217, 217, 0.3) 33%
    );
    background-size: 800px 100px;
    animation: animatedBackground 3s infinite ease-out;

    &.br {
      border-radius: 3px;
    }

    &.icon-box {
      height: 24px;
      width: 24px;
    }

    &.box1 {
      animation-delay: 0.2s;
    }

    &.box2 {
      animation-delay: 0.375s;
    }

    &.box3 {
      animation-delay: 0.55s;
    }

    &.box4 {
      animation-delay: 0.725s;
    }

    &.box5 {
      animation-delay: 0.9s;
    }

    &.box6 {
      animation-delay: 1.075s;
    }

    &.card-title {
      width: 50%;
      height: 2em;
    }

    &.text-box {
      height: 40px;
      width: 90%;
      margin-bottom: 20px;
    }

    &.row-text {
      height: 14px;
      margin-bottom: 5px;
    }

    &.r1c1 {
      width: 40%;
    }

    &.r1c2 {
      width: 25%;
      animation-delay: 1.1s;
    }

    &.r2c1 {
      width: 20%;
    }

    &.r2c2 {
      width: 35%;
      animation-delay: 1s;
    }

    &.r3c1 {
      width: 30%;
    }

    &.r3c2 {
      width: 15%;
      animation-delay: 1.2s;
    }
  }

  .type-renderer-row {
    width: 100%;
    padding: 4px;
    margin-top: 8px;
  }
}
