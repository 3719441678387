@import "./colors.scss";
@import "./skeleton.scss";
@import "./eui-styles.scss";

.form-warning-background {
  color: $dark_black;
  background-color: $dark_warning;
  text-decoration: none !important;
}
.form-warning-background:disabled {
  color: #98A2B3;
  background-color: #69707D;
}

.form-warning-text {
  color: $dark_warning !important;
  text-decoration: none !important;
}

.euiHeader--dark {
  background-color: black;
}

.euiSuperSelect__item {
  background-color: #525761;
  &:hover {
    text-decoration: none;
  }
  &:focus {
    text-decoration: none;
  }
}

.euiOverlayMask--aboveHeader {
  z-index: 9999;
}

.eui-title-bar-tooltip {
  &:not(.baseball-card-tooltip) {
    margin-left: -90px;
  }

  margin-top: -10px;
  text-transform: capitalize;
  border-radius: 8px;
  background-color: $dark_warning;
  color: $dark_black_darkest;

  & .euiToolTip__arrow {
    display: none;
  }

  &:last-child {
    padding: 0 15px !important;
    font-weight: 600 !important;
    font-size: 13px !important;
    line-height: 18px !important;
  }
}

.cancel-button {
  color: #d3dae6;
  background-color: transparent !important;
  text-decoration: none !important;
}
.cancel-btn {
  color: $dark_white_one;
  background-color: inherit;

  &:focus {
    background-color: inherit !important;
  }

  &:hover {
    background-color: inherit !important;
  }
}

.enabled-button {
  background-color: #fec514;
}

.disabled-button {
  opacity: 0.4;
  background-color: #fec514;
  color: black;
}

.full-size {
  width: 100% !important;
  height: 100% !important;
}

.full-width {
  width: 100% !important;
}

.full-height {
  height: 100% !important;
}

.euiGlobalToastList--right:not(:empty) {
  padding: 0px !important;
}

.euiGlobalToastList:not(:empty) {
  width: 330px !important;
  margin: 16px !important;
  margin-top: 32px !important;
}

.euiButton--warning.euiButton--fill {
  background-color: $dark_warning !important;
  border-color: $dark_warning !important;
}

.euiButtonEmpty--primary {
  color: $dark_white;
}

*:focus:focus-visible {
  outline-style: none;
}

.euiComboBoxOptionsList {
  z-index: 9000 !important;
  .euiComboBoxOptionsList__rowWrap {
    background-color: $dark_grey_nine !important;
    box-shadow: none !important;
    border-radius: 4px !important;

    & > div {
      overflow-x: hidden !important;
    }
    .euiFilterSelectItem {
      border-bottom: 1px solid $dark_grey_four !important;
      padding: 4px 6px;
    }
    .euiFilterSelectItem-isFocused {
      color: #dfe5ef !important;
      background-color: inherit !important;

      .euiBadge {
        display: none !important;
      }
    }
    button:not(.combobox-menu-pill__cross):hover {
      color: $dark_warning !important;
      text-decoration: none !important;
    }
    button + button {
      margin-left: 0 !important;
    }
  }
}
.euiComboBox.euiComboBox-isOpen .euiComboBox__inputWrap {
  background-image: linear-gradient(
    to top,
    $dark_warning,
    $dark_warning 2px,
    transparent 2px,
    transparent 100%
  ) !important;
}
