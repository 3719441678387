@import "styles/colors.scss";

.blue-btn {
  box-sizing: border-box;
  background: #0071c2 !important;

  font-weight: 500;
  font-size: 12px;
  line-height: 18px;

  align-items: center;
  text-transform: uppercase;
  color: $dark_white !important;
  margin-right: 15px;
}

.euiButton,
.euiButtonEmpty {
  text-decoration: none !important;
}

.euiButton.euiButton-isDisabled:not(.euiButton--ghost) {
  opacity: 0.5;
}

.euiCheckbox__input + .euiCheckbox__square {
  background-color: $dark_white !important;
}

.euiCheckbox .euiCheckbox__input:checked + .euiCheckbox__square {
  background-color: #36a2ef !important;
  background-image: url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2710%27 height=%278%27 viewBox=%270 0 10 8%27%3E%3Cpath d=%27M.375 2.625L3.375 5.625M3.375 5.625L8.625.375%27 fill=%27none%27 fill-rule=%27evenodd%27 stroke=%27rgb%28255, 255, 255%29%27 stroke-linecap=%27round%27 stroke-width=%271.5%27 transform=%27translate%28.5 1%29%27/%3E%3C/svg%3E") !important;
}

.yellow-btn {
  box-sizing: border-box;
  border-radius: 6px;
  background: $dark_warning !important;

  font-weight: 500;
  font-size: 14px;
  border: none;

  align-items: center;
  color: $dark_black_darkest;
}

.grey-yellow-button-group {
  & > .euiButtonGroup__buttons {
    background-color: $dark_grey_four !important;
  }

  .euiButtonGroupButton.euiButtonGroupButton-isSelected {
    background-color: $dark_warning !important;
    border-color: $dark_warning !important;
    color: $dark_black_darkest !important;
    text-decoration: none !important;
  }
}

.grey-white-button-group {
  & > .euiButtonGroup__buttons {
    background-color: $dark_grey_four;
  }

  .euiButtonGroupButton.euiButtonGroupButton--text:not([class*="isDisabled"]).euiButtonGroupButton-isSelected {
    background-color: $dark_white;
    border-color: $dark_white;
    color: $dark_black_darkest;
  }
}

.grey-white-combobox {
  .euiComboBox__inputWrap {
    background-color: $dark_grey_nine !important;
    border-width: 0px;
    box-shadow: none;
    border-radius: 4px;

    & > .euiComboBoxPlaceholder {
      color: $dark_grey_nine_text;
      opacity: 0.5;
    }
  }
  .euiComboBoxPill {
    background: $dark_warning !important;
    color: $dark_black_darkest !important;
  }
}

.z-1 {
  z-index: 1;
}

.bg-transparent {
  background-color: rgba(0, 0, 0, 0) !important;
}

.bg-black-gradient-down {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.3785889355742297) 65%,
    rgba(0, 0, 0, 0) 92%
  ) !important;
}

.box-shadow-none {
  box-shadow: none;
}

.w-fit-content {
  width: fit-content;
}

.text-align-right {
  text-align: right;
}

.padding-right-10px {
  padding-right: 10px;
}

/* Styling to increase visibility for overlapping modals within the application */
.modal-visibility {
  border: 1px solid #525761;
  border-radius: 8px;
  box-shadow: 0px 4px 20px 0px #00000033; // default shadow for unselected modals
  &.in-focus-shadow {
    box-shadow: 0px 4px 40px 0px #0000004d;
  }
}

/**
  * Fixes issue where dropdown selection is hidden by our dynamic
  * zIndex selectors
  */
.euiPopover__panel-isOpen {
  z-index: 9999 !important;
}

.euiHorizontalRule {
  background-color: $dark_grey_four !important;
}
