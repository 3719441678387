@import "/src/styles/global.scss";
@import "/src/styles/fonts.scss";

html {
  height: 100%;
  background-color: black;
}

body {
  height: 100%;
  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  scrollbar-color: #5e636f rgba(52, 55, 65, 0.95);

  #root {
    height: 100%;
    padding-bottom: 20px;
    padding-top: 20px;
    position: relative;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

// Add !important to enforce scrollbar properties
::-webkit-scrollbar {
  background-color: transparent !important;
  height: 12px !important; // This is for horizontal scrollbar
  width: 12px !important;
}

::-webkit-scrollbar-track {
  display: none !important;
  border-radius: 8px !important;
}

::-webkit-scrollbar-thumb {
  background-color: #69707d !important;
  height: 40px;
  border: none !important; //EUI combobox and superselect not displaying scrollbar-thumb when default border option is present
  border-radius: 8px !important;
  opacity: 0.6 !important;
}
