.loading-page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  overflow: hidden;

  .loading-screen-background {
    opacity: 0.8;
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
  }
  .loading-screen {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: calc(50vh - 125px); // half screen height minus half .loading-screen height
    width: 655px;
    height: 250px;
    background-color: #343741;
    border-radius: 8px;
  }

  .loading-screen-header {
    margin-top: 35px;
    margin-bottom: 0px;
    font-family: "Arial", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 48px;
    color: #fec514;
  }

  .loading-message {
    margin: 20px 5px;
    font-family: "Arial", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 32px;
  }

  .error-message {
    margin: 20px 10px 0px;
    font-family: "Arial", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
  }
}

.root-adjustment {
  height: calc(100vh - 40px) !important;
}

.loading-overlay {
  /* Opacity BG */
  position: absolute;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;

  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;

  /* Core / Darkest - $euiColorDarkestShade */

  background: #343741;
  opacity: 0.8;

  .center-text {
    text-align: center;

    .euiTitle {
      color: white;

      span {
        font-size: 2.4286rem;
        line-height: 2.8571rem;
        font-weight: 700;
        display: block;
      }
      h1 {
        margin-block-end: 0 !important;
      }
    }

    .loading-bar-container {
      max-width: 200px;
      margin: auto;
      .loading-bar-path {
        border-radius: 1em;
        position: relative;
        overflow: hidden;
        max-width: 200px;
        height: 1vh;
        background-color: #c1c1c1;
        margin: auto;
        .loading-bar-element-shape {
          position: absolute;
          left: 0;
          background-color: #0077cc;
          width: 3vh;
          height: 80px;
          display: block;
          top: 0;
          animation: ani 1.7s 1;
          animation-iteration-count: infinite;
        }
      }
      @keyframes ani {
        0% {
          left: 0;
        }
        50% {
          left: calc(200px - 3vh);
        }
        100% {
          left: 0;
        }
      }
    }
    .loading-fetch-text {
      font-size: 14px;
      font-weight: 600;
      color: #fec514;
    }
  }
}
