@import "/src/styles/colors.scss";

.security-banner {
  border: none !important;
  border-radius: 0 !important;
  font-size: 15px !important;
  font-weight: normal;
  margin: 0 !important;
  height: 22px;
  padding: 2px 15px 3px !important;
  position: fixed !important;
  text-align: center;
  width: 100%;
  z-index: 9999 !important;

  &.upper {
    top: 0;
  }

  &.lower {
    bottom: 0;
  }

  &.exercise {
    background-color: $exercise_banner;
    color: $exercise_text;
  }

  &.unclass {
    background-color: $unclassified_banner;
    color: $unclassified_text;
  }

  &.confidential {
    background-color: $confidential_banner;
    color: $confidential_text;
  }

  &.secret {
    background-color: $secret_banner;
    color: $secret_text;
  }

  &.secret.sci {
    background-color: $secret_sci_banner;
    color: $secret_sci_text;
  }

  &.ts {
    background-color: $top_secret_banner;
    color: $top_secret_text;
  }

  &.ts.sci {
    background-color: $top_secret_sci_banner;
    color: $top_secret_sci_text;
  }
}
